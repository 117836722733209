<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="7"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search..."
            style="width: 50%;"
            @input="handleSearch"
          />
          <b-button
            variant="primary"
            @click="ExportDataExcel()"
          >
            <feather-icon
              icon="ExternalLinkIcon"
              class="mr-50"
            />
            <span class="align-middle">Export</span>
          </b-button>
        </b-col>

        <b-col
          cols="12"
          md="5"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group style="height: 38px;">
              <flat-pickr
                v-model="rangeDate"
                class="form-control mb-1"
                :config="{ mode: 'range', dateFormat: 'Y-m-d' }"
              />
              <b-input-group-append style="height: 38px;">
                <b-button
                  variant="outline-primary"
                  class="fs-8"
                  size="sm"
                  @click="updateData('this_bi_week')"
                >
                  This Bi Week
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="fs-8"
                  size="sm"
                  @click="updateData('last_bi_week')"
                >
                  Last Bi Week
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="tableProfiles"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      style="position: relative !important;"
      @sort-changed="onSortChange"
    >
      <!-- Column: Patient -->
      <template #cell(Name)="data">
        <span class="text-nowrap font-weight-bold">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: First Check -->
      <template #cell(Poss)="data">
        <span class="text-nowrap font-weight-bold">
          {{ data.value }}
        </span>
      </template>

      <template #cell(Reg_Hrs)="data">
        <div v-if="data.value === ''">
          <span class="text-nowrap font-weight-bold">
            <feather-icon
              icon="ClockIcon"
              class="mr-50"
            />
            --:--
          </span>
        </div>
        <div v-else>
          <span class="text-nowrap font-weight-bold styleboldblack">
            <feather-icon
              icon="ClockIcon"
              class="mr-50"
            />
            {{ data.value }}
          </span>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            :current-page="currentPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="handleChangePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, VBToggle, BFormInput, BButton, BPagination, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import timeSheetService from '@core/services/timeSheets/timeSheets'
import authService from '@core/services/auth/authService'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BButton,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      fields: [{ key: 'Name', label: 'Name', sortable: true }, { key: 'Poss', label: 'Poss', sortable: true }, { key: 'Reg_Hrs', label: 'Reg Hrs', sortable: true }],
      items: [],
      perPage: 15,
      actualpage: 1,
      totalRows: 0,
      currentPage: 1,
      FullCountEntries: 0,
      sortBy: 'Name',
      sortDesc: false,
      sortDirection: 'asc',
      searchTerm: '',
      getDataUser: {},
      permission: true,
      rangeDate: null,
      date1: '',
      date2: '',
    }
  },
  watch: {
    perPage() {
      this.getBreakDownPayroll()
    },
    /* eslint-disable */
    rangeDate(val) {
      if (val.split('to').length > 1) {
        const date = val.split('to')
        this.date1 = moment(date[0].trim()).format('YYYY-MM-DD')
        this.date2 = moment(date[1].trim()).format('YYYY-MM-DD')
        this.getBreakDownPayroll()
      }
    },
    /* eslint-enable */
  },
  created() {
    this.date1 = moment().date() < 15 ? moment().startOf('month').format('YYYY-MM-DD') : moment(moment().format('YYYY-MM-16')).format('YYYY-MM-DD')
    this.date2 = moment().date() < 15 ? moment(moment().format('YYYY-MM-15')).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD')
    this.updateData('this_bi_week')
    this.getBreakDownPayroll()
  },
  methods: {
    ExportDataExcel() {
      timeSheetService.exportDataExcel({
        initialDate: this.date1,
        finalDate: this.date2,
      }).then(response => {
        const objectURL = URL.createObjectURL(response.data)
        const exportLinkElement = document.createElement('a')
        exportLinkElement.hidden = true
        exportLinkElement.download = `Time Sheet Report ${this.date1} to ${this.date2}.xlsx`
        exportLinkElement.href = objectURL
        exportLinkElement.text = 'downloading...'
        document.body.appendChild(exportLinkElement)
        exportLinkElement.click()
        URL.revokeObjectURL(objectURL)
        exportLinkElement.remove()
      })
    },
    Create() {
      this.getDataUser = null
    },
    getDataofUser(id) {
      authService.getUserById({
        id,
      }).then(response => {
        this.getDataUser = response.data.user
      })
    },
    getBreakDownPayroll() {
      timeSheetService.getBreakDownPayroll({
        initialDate: this.date1,
        finalDate: this.date2,
        nameSearch: this.searchTerm,
        page: this.actualpage,
        rows: this.perPage,
        orderBy: this.sortBy,
        sortBy: this.sortDirection,
      }).then(response => {
        if (Object.keys(response.data.data).length !== 0) {
          this.items = response.data.data
          this.FullCountEntries = response.data.data[0].full_count
          this.numPages = response.data.data[0].pages
          this.totalRows = this.FullCountEntries
          this.currentPage = this.actualpage
        } else {
          this.items = []
          this.FullCountEntries = 0
          this.numPages = 0
          this.totalRows = this.FullCountEntries
          this.currentPage = this.actualpage
        }
      })
    },
    handleSearch(searching) {
      this.searchTerm = searching
      this.actualpage = 1
      this.totalRows = 1
      this.getBreakDownPayroll()
    },
    handleChangePage(page) {
      this.actualpage = page
      this.getBreakDownPayroll()
    },
    onSortChange(params) {
      this.sortBy = params.sortBy
      this.sortDesc = params.sortDesc
      const direction = params.sortDesc === true ? 'desc' : 'asc'
      this.sortDirection = direction
      this.getBreakDownPayroll()
    },
    updateData(time) {
      const thisBiWeekstartDate = moment().date() < 15 ? moment().startOf('month').format('YYYY-MM-DD') : moment(moment().format('YYYY-MM-16')).format('YYYY-MM-DD')
      const thisBiWeekendDate = moment().date() < 15 ? moment(moment().format('YYYY-MM-15')).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD')
      const lastBiWeekstartDate = moment().date() > 15 ? moment().subtract(16, 'days').startOf('month').format('YYYY-MM-DD') : moment(moment().subtract(16, 'days').format('YYYY-MM-16')).format('YYYY-MM-DD')
      const lastBiWeekendDate = moment().date() > 15 ? moment(moment().subtract(16, 'days').format('YYYY-MM-15')).format('YYYY-MM-DD') : moment().subtract(16, 'days').endOf('month').format('YYYY-MM-DD')
      switch (time) {
        case 'this_bi_week':
          this.rangeDate = `${thisBiWeekstartDate} to ${thisBiWeekendDate}`
          break
        case 'last_bi_week':
          this.rangeDate = `${lastBiWeekstartDate} to ${lastBiWeekendDate}`
          break
        default:
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.invoice-filter-select {
  min-width: 280px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
    width: 100% !important;
  }

  .vs__selected {
    width: 100% !important;
  }
}

.styleboldblack {
    font-weight: bold;
    color: black;
}

.b-avatar .b-avatar-text{
    font-size: 10px !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
