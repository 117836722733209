import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class AuthService {
  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  login(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  verifyAuthyCode(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.verifyAuthyCodeEndpoint, ...args)
  }

  signUp(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.signUp, ...args)
  }

  updateUser(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.updateUser, ...args)
  }

  deleteUser(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.deleteUser, ...args)
  }

  getUserById(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.getUserById, ...args)
  }

  createCandidate(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.createCandidate, ...args)
  }

  updateCandidate(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.updateCandidate, ...args,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
  }
}

function useAuthService() {
  const authService = new AuthService()

  return {
    authService,
  }
}

const { authService } = useAuthService()
export default authService
