import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class TimeSheetsService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getPayrollIndividual(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getPayrollIndividual, ...args)
    }

    getAllUsers(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getAllUsers, ...args)
    }

    getBreakDownPayroll(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getBreakDownPayroll, ...args)
    }

    getCheckInOutDay(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getCheckInOutDay, ...args)
    }

    createCheckInOut(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.createCheckInOut, ...args)
    }

    exportDataExcel(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.exportDataExcel, ...args, { responseType: 'blob' })
    }
}

function useTimeSheetsService() {
  const timeSheetService = new TimeSheetsService()

  return {
    timeSheetService,
  }
}

const { timeSheetService } = useTimeSheetsService()
export default timeSheetService
